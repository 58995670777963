<template>
  <div class="page-coming-soon">

    <h1>Coming Soon</h1>

    <MorphingGooeyAnimation v-if="false" :delay="2000" :initial-delay="500">
      <svg viewBox="0 0 110 20" preserveAspectRatio="xMinYMid meet">
        <defs>
            <filter id="goo-1">
              <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur"></feGaussianBlur>
              <feColorMatrix in="blur" mode="matrix" values=" 1 0 0 0 0  
                                                              0 1 0 0 0  
                                                              1 0 1 0 0  
                                                              0 0 0 16 -7" result="goo"></feColorMatrix>
              <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
            </filter>
          </defs>
          <g>
            <text x="7" y="10">Coming Soon</text>
            <text x="0" y="10" fill="#ffbf16">Hashgalleries</text>

            <!-- <text x="7" y="10" fill="rgba(145, 234, 228, 0.2)">Coming Soon</text> -->
            <!-- <text x="0" y="10" fill="rgba(127, 127, 213, 0.2)">Hashgalleries</text> -->
          </g>
      </svg>
    </MorphingGooeyAnimation>

    <div class="test-test" v-if="false">
      <!-- <svg viewBox="0 0 110 20" width="100%" height="100%" preserveAspectRatio="xMinYMid meet"> -->
      <svg viewBox="-500 -500 1000 1000" width="100%" height="100%" preserveAspectRatio="xMidYMin slice">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -5" result="goo"></feColorMatrix>
            <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
          </filter>
        </defs>
        <g filter="url(#goo)">
          <text x="0" y="0">Coming Soon</text>
          <text x="0" y="0">Hashgalleries</text>
          <!-- <text x="0" y="150">8</text>
          <text x="0" y="150">7</text>
          <text x="0" y="150">6</text>
          <text x="0" y="150">5</text>
          <text x="0" y="150">4</text>
          <text x="0" y="150">3</text>
          <text x="0" y="150">2</text>
          <text x="0" y="150">1</text>
          <text x="0" y="150">GO</text> -->
        </g>
      </svg>
    </div>



  </div>
</template>

<script>

// import WebFont from 'webfontloader';

import MorphingGooeyAnimation from '@/components/MorphingGooeyAnimation.vue';


export default {
  name: 'ComingSoon',
  components: {
    MorphingGooeyAnimation,
  },

  created() {
    // WebFont.load({
    //   google: {
    //     families: ['Inconsolata']
    //   },
    //   active: () => {
    //     // alert('loaded')
    //   },
    // });
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .page-coming-soon {
    min-height: 100vh;
    color: #fff;
    background-color: #000;
    text-align: center;
    font-family: "Inconsolata", monospace;
    font-size: 36px;
    line-height: 48px;
    padding: 0 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
    }

    .test-effect {
      height: 100vh;
      max-width: 500px;
      width: 100%;
    }

    // div {
    //   background: linear-gradient(110.78deg, rgb(118, 230, 80));
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    //   color: transparent;
    // }
  }

  .test-test {
    height: 100vh;
    max-width: 500px;
    width: 100%;




    transition: none;
    position: relative;

    display: flex;
    /* justify-content: center; */
    /* text-align: center; */
    margin: auto;

    svg {
      font-size: 17px;

      fill: #fff;
      display: block;
      font-weight: 700;
      pointer-events: none;

      g {
        & > * {
        // text {
          transform-origin: 50% 50%;
          transform-box: view-box;
          max-width: 50px;
        }

        & > *:nth-child(2) { 
        // text:nth-child(2) {
          opacity: 0;
        }
      }
    } // svg

     // svg {
    //   margin: auto;
    //   width: 100vmin;
    // }

    g > text {
      // text-anchor: middle;
      // font-size: 400px;
      // fill: hsl(210, 100%, 75%);
      fill: #fff;
      color: #fff;
      // opacity: 0;
      // animation-name: opac;
      // animation-duration: 4s;
      // animation-duration: 11s;
      // animation-timing-function: linear;
      // animation-iteration-count: infinite;
    }

    // g > text:nth-child(1) { animation-delay: 0s; }
    // g > text:nth-child(2) { animation-delay: 2s; }
    // g > text:nth-child(3) { animation-delay: 2s; }
    // g > text:nth-child(4) { animation-delay: 3s; }
    // g > text:nth-child(5) { animation-delay: 4s; }
    // g > text:nth-child(6) { animation-delay: 5s; }
    // g > text:nth-child(7) { animation-delay: 6s; }
    // g > text:nth-child(8) { animation-delay: 7s; }
    // g > text:nth-child(9) { animation-delay: 8s; }
    // g > text:nth-child(10) { animation-delay: 9s; }
    // g > text:nth-child(11) { animation-delay: 10s; }

    @keyframes opac {
      0% { opacity: 0; }
      5% { opacity: 1; }
      50% { opacity: 1; }
      60% { opacity: 0; }
      100% { opacity: 0; }
    }

    // @keyframes opac {
    //   0% { opacity: 0; }
    //   7.5% { opacity: 1; }
    //   10% { opacity: 1; }
    //   15% { opacity: 0; }
    //   100% { opacity: 0; }
    // }

  } // .test-test

</style>
