import Web3 from 'web3';

import galleriesAbi from '@/assets/GalleriesAbi.json';

import masksAbi from '@/assets/HashmasksAbi.json';
import nctAbi from '@/assets/NCTAbi.json';
import baycAbi from '@/assets/BAYCAbi.json';

/*
* 1. Check for injected web3 (mist/metamask)
* 2. If metamask/mist create a new web3 instance and pass on result
* 3. Get networkId - Now we can check the user is connected to the right network to use our dApp
* 4. Get user account from metamask
* 5. Get user balance
*/

async function getWeb3() {

  // https://github.com/ChainSafe/web3.js/issues/1362

  // let provider = 'https://bsc-dataseed1.binance.org:443';
  let provider = window.ethereum;
  // if (window.ethereum) {
  //   provider = window.ethereum;
  // }
  // else {
  //   provider = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8545' : null;
  //   // provider = 'https://data-seed-prebsc-1-s1.binance.org:8545';
  // }

  // var web3 = new Web3(new Web3.providers.HttpProvider('http://127.0.0.1:9545'));
  // var web3 = new Web3(Web3.givenProvider)
  // var web3 = new Web3(window.ethereum) // финальный результат для метамаска
  const web3 = new Web3(provider);

  const galleriesContractInstance = new web3.eth.Contract(galleriesAbi, process.env.VUE_APP_GALLERIES_ADDRESS)
  const baycContractInstance = new web3.eth.Contract(baycAbi, process.env.VUE_APP_BAYC_ADDRESS)
  const masksContractInstance = new web3.eth.Contract(masksAbi, process.env.VUE_APP_MASKS_ADDRESS)
  const nctContractInstance = new web3.eth.Contract(nctAbi, process.env.VUE_APP_NCT_ADDRESS)

  const networkId = await web3.eth.net.getId();

  return {
    // injectedWeb3: web3.isConnected(),
    networkId: networkId,
    galleriesContractInstance,
    baycContractInstance,
    masksContractInstance,
    nctContractInstance,

    web3Instance () {
      return web3
    }
  }

} // function

// async function getWeb3Accounts(isConnectWithMetamaskProvider) {
async function getWeb3Accounts(nctContractInstance) {
  let accounts;
  let nctBalance;
  let nctAllowance;
  try {
    accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    const nctBalanceInWei = await nctContractInstance.methods.balanceOf(accounts[0]).call();
    nctBalance = Number(Web3.utils.fromWei(nctBalanceInWei, 'ether'));

    const nctAllowanceInWei = await nctContractInstance.methods.allowance(accounts[0], process.env.VUE_APP_GALLERIES_ADDRESS).call();
    nctAllowance = Number(Web3.utils.fromWei(nctAllowanceInWei, 'ether'));
  }
  catch (error) {
    console.log('getWeb3 ERROR', error)
    // reject(new Error('Unable to connect to Metamask'))
    // if (error.code === 4001) {
    //   // User rejected request
    // }
    // setError(error);
  }

  return {accounts, nctBalance, nctAllowance};
}

// export default getWeb3
export {getWeb3, getWeb3Accounts}



    /*
    var web3js = window.web3
    if (typeof web3js !== 'undefined') {
      var web3 = new Web3(web3js.currentProvider)
      console.log('test', web3js.currentProvider, Web3.givenProvider)
      resolve({
        // injectedWeb3: web3.isConnected(),
        web3 () {
          return web3
        }
      })
    } else {
      // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
      reject(new Error('Unable to connect to Metamask'))
    }
    */

  // .then(result => {
  //   return new Promise(function (resolve, reject) {
  //     // Retrieve network ID
  //     result.web3().version.getNetwork((err, networkId) => {
  //       if (err) {
  //         // If we can't find a networkId keep result the same and reject the promise
  //         reject(new Error('Unable to retrieve network ID'))
  //       } else {
  //         // Assign the networkId property to our result and resolve promise
  //         result = Object.assign({}, result, {networkId})
  //         resolve(result)
  //       }
  //     })
  //   })
  // })

  // .then(result => {
  //   return new Promise(function (resolve, reject) {
  //     // Retrieve coinbase
  //     result.web3().eth.getCoinbase((err, coinbase) => {
  //       if (err) {
  //         reject(new Error('Unable to retrieve coinbase'))
  //       } else {
  //         result = Object.assign({}, result, { coinbase })
  //         resolve(result)
  //       }
  //     })
  //   })
  // })
  // .then(result => {
  //   return new Promise(function (resolve, reject) {
  //     // Retrieve balance for coinbase
  //     result.web3().eth.getBalance(result.coinbase, (err, balance) => {
  //       if (err) {
  //         reject(new Error('Unable to retrieve balance for address: ' + result.coinbase))
  //       } else {
  //         result = Object.assign({}, result, { balance })
  //         resolve(result)
  //       }
  //     })
  //   })
  // })
