import Vue from 'vue';
import VueRouter from 'vue-router';

// import Home from '@/views/Home.vue';
// import Test3D from '@/views/Test3D.vue';
import ComingSoon from '@/views/ComingSoon.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'ComingSoon',
  //   component: ComingSoon,
  // },
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/test-3d',
    name: 'Test3D',
    // component: Test3D,
    component: () => import(/* webpackChunkName: "test3d" */ '../views/Test3D.vue'),
  },
  {
    path: '/promo-video',
    name: 'PromoVideo',
    component: () => import(/* webpackChunkName: "promovideo" */ '../views/PromoVideo.vue'),
  },
  {
    path: '/promo-video2',
    name: 'PromoVideo2',
    component: () => import(/* webpackChunkName: "promovideo2" */ '../views/PromoVideo2.vue'),
  },
  {
    path: '/early-access',
    name: 'HomeEarlyAccess',
    component: () => import(/* webpackChunkName: "earlyaccess" */ '../views/HomeEarlyAccess.vue'),
  },
  {
    path: '/provenance',
    name: 'Provenance',
    component: () => import(/* webpackChunkName: "provenance" */ '../views/Provenance.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
