import Vue from 'vue';
import Vuex from 'vuex';


import {getWeb3, getWeb3Accounts} from '@/util/getWeb3';

// import firebaseInstance from '@/firebaseConfig.js';
// import { db, auth } from '@/firebaseConfig.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    web3: {
      web3Instance: null,
      networkId: null,
      accounts: null,
      galleriesContractInstance: null,
      baycContractInstance: null,
      masksContractInstance: null,
      nctContractInstance: null,

      nctBalance: null,
      nctAllowance: null,

    //   // isInjected: false,
    //   // coinbase: null,
    //   // balance: null,
    //   // error: null
    },
  },
  mutations: {
    registerWeb3Instance (state, payload) {
      // web3Copy.coinbase = payload.coinbase
      // web3Copy.balance = parseInt(payload.balance, 10)

      /*
      let web3Copy = state.web3
      web3Copy.networkId = payload.networkId
      web3Copy.web3Instance = payload.web3Instance
      web3Copy.accounts = payload.accounts
      state.web3 = web3Copy
      */

      const newWeb3 = {
        web3Instance: payload.web3Instance,
        networkId: payload.networkId,
        galleriesContractInstance: payload.galleriesContractInstance,
        baycContractInstance: payload.baycContractInstance,
        masksContractInstance: payload.masksContractInstance,
        nctContractInstance: payload.nctContractInstance,
        // accounts: payload.accounts,
      }

      state.web3 = Object.assign({}, state.web3, newWeb3);
    },

    setAccounts(state, accountsAndBalance) {
      // let web3Copy = state.web3;
      // web3Copy.accounts = accounts;
      // state.web3 = web3Copy;
      state.web3 = Object.assign({}, state.web3, accountsAndBalance);
    },

    decreaseNctBalanceAndAllowance(state, amountToDecrease) {
      const newBalances = {
        nctBalance: state.web3.nctBalance - amountToDecrease,
        nctAllowance: state.web3.nctAllowance - amountToDecrease,
      };
      state.web3 = Object.assign({}, state.web3, newBalances);
    },

    // registerWeb3WithRawData (state, payload) {
    //   let web3Copy = state.web3
    //   web3Copy.networkId = payload.networkId
    //   web3Copy.web3Instance = payload.web3Instance
    //   web3Copy.accounts = payload.accounts

    //   state.web3 = web3Copy
    // },
  },
  actions: {
    registerWeb3 ({commit}) {
      return getWeb3()
        .then(result => {
          commit('registerWeb3Instance', result)
        })
        .catch(e => {
          console.log('error in action registerWeb3', e)
        })
    },

    async registerWeb3Accounts ({commit, state}) {
      let accountsAndBalance;
      try {
        accountsAndBalance = await getWeb3Accounts(state.web3.nctContractInstance);
      }
      catch (e) {
        console.log('error in action setAccounts', e);
      }

      commit('setAccounts', accountsAndBalance);
    },

    decreaseNctBalanceAndAllowance ({commit}, amountToDecrease) {
      commit('decreaseNctBalanceAndAllowance', amountToDecrease)
    },

    // registerWeb3WithRawData ({commit}, result) {
    //   commit('registerWeb3WithRawData', result);
    // },
  },
  modules: {
  },
});
