import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
// Vue.use(ElementUI, { lang });

locale.use(lang);

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://f27ed061b13346f9ae591311752b5746@o280113.ingest.sentry.io/5780439",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');