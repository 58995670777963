<template>
  <div class="test-effect">
    <slot></slot>
  </div>
</template>

<script>

import { gsap } from 'gsap';

export default {
  data() {
    return {
      DOM: null,
      filterId: null,
      primitiveValues: null,
      tl: null,
    };
  }, // data

  props: {
    delay: {
      type: Number,
      required: true,
    },

    initialDelay: {
      type: Number,
      required: true,
    },

    speed: {
      type: Number,
      default: 1,
    },
  },

 mounted() {
    const el = document.querySelector('.test-effect');
    this.init(el);
  },

  methods: {
    init(el) {
      this.DOM = {el: el};
      this.DOM.textsGroupEl = this.DOM.el.querySelector('svg > g');
      this.filterId = this.DOM.el.querySelector('svg filter').id;

      // [this.DOM.text_1, this.DOM.text_2] = this.DOM.textsGroupEl.querySelectorAll('text');
      [this.DOM.text_1, this.DOM.text_2] = this.DOM.textsGroupEl.querySelectorAll('*');

      this.DOM.feBlur = document.querySelector(`#${this.filterId} > feGaussianBlur`);
      this.primitiveValues = {stdDeviation: 0};

      this.createTimeline();


      // this.initEvents(false);
      setTimeout(() => this.initEvents(false), this.initialDelay);
    },

    initEvents(isPlayed) {
      // const onMouseEnterFn = () => {
      //   this.DOM.textsGroupEl.style.filter = `url(#${this.filterId})`;
      //   this.tl.play();
      // }
      // const onMouseLeaveFn = () => {
      //   this.DOM.textsGroupEl.style.filter = `url(#${this.filterId})`;
      //   this.tl.reverse();
      // }
      // this.DOM.el.addEventListener('mouseenter', onMouseEnterFn);
      // this.DOM.el.addEventListener('mouseleave', onMouseLeaveFn);
      
      this.DOM.textsGroupEl.style.filter = `url(#${this.filterId})`;
      if (isPlayed) this.tl.reverse();
      else this.tl.play();

      setTimeout(() => this.initEvents(!isPlayed), this.delay);
    }, // initEvents

    createTimeline() {
      // init timeline
      this.tl = gsap.timeline({
        paused: true,
        onComplete: () => {
          this.DOM.textsGroupEl.style.filter = 'none';
        },
        onReverseComplete: () => {
          this.DOM.textsGroupEl.style.filter = 'none';
        },
        onUpdate: () => {
          this.DOM.feBlur.setAttribute('stdDeviation', this.primitiveValues.stdDeviation)
        }
      })
      .to(this.primitiveValues, { 
        duration: this.speed / 2,
        ease: "none",
        startAt: {stdDeviation: 0},
        stdDeviation: 1.5
      }, 0)
      .to(this.primitiveValues, { 
        duration: this.speed / 2,
        ease: "none",
        stdDeviation: 0
      })
      .to(this.DOM.text_1, { 
        duration: this.speed,
        ease: "none", // Power1.easeInOut
        opacity: 0
      }, 0)
      .to(this.DOM.text_2, { 
        duration: this.speed,
        ease: "none", // Power1.easeInOut
        opacity: 1
      }, 0)
      .to(this.DOM.text_1, { 
        duration: this.speed,
        ease: "Power2.easeInOut",
        // x: 8
      }, 0)
      .to(this.DOM.text_2, { 
        duration: this.speed,
        ease: "Power2.easeInOut",
        // startAt: {x: -8},
        // x: 0
      }, 0);
    }, // createTimeline
  }, // methods

}
</script>

<style lang="scss" scoped>

  .test-effect {
    transition: none;
    position: relative;

    display: flex;
    /* justify-content: center; */
    /* text-align: center; */
    margin: auto;

    svg {
      font-size: 17px;

      fill: #fff;
      display: block;
      font-weight: 700;
      pointer-events: none;

      g {
        & > * {
        // text {
          transform-origin: 50% 50%;
          transform-box: view-box;
          max-width: 50px;
        }

        & > *:nth-child(2) { 
        // text:nth-child(2) {
          opacity: 0;
        }
      }
    } // svg

  } // .test-effect

</style>