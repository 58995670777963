<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/mywallet">My Wallet</router-link>
      <p>
        <span>Marketplace</span>
        <span>(coming soon)</span>
      </p>
    </div> -->
    <router-view/>

    <!-- <div class="footer" v-if="$route.name === 'Home'"> -->
    <div class="footer" v-if="$route.name">
      <h1 class="logo">
        <router-link to="/">
          Bored Hash <span>Galleries</span>.
        </router-link>
      </h1>

      <div class="footer__links">
        <!-- <a href="https://boredhash.com" target="_blank">Airdrop</a> -->
        <!-- <a href="https://twitter.com/boredhash/status/1403725339742904326" target="_blank">Giveaway</a> -->
        <a href="https://twitter.com/boredhash" target="_blank">
          <img src="@/assets/twitter-logo.png">
          Twitter
        </a>
        <a href="https://discord.gg/HExg2bx6uX" target="_blank">
          <img src="@/assets/discord-logo.svg">
          Discord
        </a>
      </div>
    </div> <!-- /.footer -->

  </div>
</template>

<script>

  /*

    Metadata info:

    https://mdex.com/certik.pdf
    https://twitter.com/Falcon_Swap/status/1353575945676832768
    https://forum.bitbay.market/t/not-enough-funds-available/2272
    https://www.reddit.com/r/storj/comments/71d6f3/storj_token_sale_details_have_been_published/
    https://medium.com/@RialtoAI/watch-for-ico-lock-up-periods-4eba39af121e
    https://medium.com/commonwealth-labs/whats-in-a-lockdrop-194218a180ca
    https://icorating.com/news/2018-8-token-lockup-periods-for-successfully-funded-ico-projects-in-q2-2018/
    https://academy.binance.com/en/glossary/token-lockup
    https://academy.binance.com/en/glossary/hashed-timelock-contract
    https://link.springer.com/article/10.1007/s11408-020-00366-0
    https://twitter.com/montymetzger/status/1270662336714588160

    https://docs.openzeppelin.com/contracts/3.x/api/token/erc20#TokenTimelock



    Name: Hashgallery #1 (timelock until 1st of June 2023)
    Description: The first dynamic NFT fully powered by Hashmasks.

  */

  // import { Menu, MenuItem } from 'element-ui';

  export default {
    components: {
      // 'el-menu': Menu,
      // 'el-menu-item': MenuItem,
    },
    beforeCreate() {
    },
    mounted() {
      this.$store.dispatch('registerWeb3')
    },
    methods: {
    },
    computed: {
    },
  };
</script>

<style lang="scss">

#nav {
  margin: 10px 30px;
  // float: right;
  text-align: right;

  .router-link-exact-active {
    border-bottom: 1px solid;
    opacity: 0.8;
 }

  p {
    display: inline-block;

    span {
      // font-size: 15px;
      display: block;

      &:last-child {
        position: absolute;
        font-size: 12px;
        line-height: 7px;
        opacity: 0.9;
      }
    }
  }

  > * {
    margin: 0 10px;
  }

  a {
    &:hover {
      // color: #000;

      border-bottom: 1px solid;
      opacity: 0.8;
    }
  }
} // #nav

a {
  color: #039be5;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  // background-color: rgb(250, 250, 250);
  margin: 0;
  // margin-bottom: 100px;

  // font-family: "Roboto", sans-serif;
  // color: rgba(0,0,0,0.87);
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;

  color: #272727;
  font-family: 'Montserrat', arial, helvetica, sans-serif;
  line-height: 1.8em;
  font-size: 18px;

  @media (max-width: 650px) {
    font-size: 15px;
  }

}

p {
  line-height: 1.8em;
}

h2,
h3 {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);

  // font-family: 'Roboto Mono', monospace;
  // font-size: 30px; // это для Roboto Mono и дефолтного шрифта
  // line-height: 38px; // это для Roboto Mono и дефолтного шрифта

  font-family: 'Inconsolata', monospace;
  font-size: 36px;
  line-height: 48px;

  // @media (max-width: 880px) {
  //   font-size: 40px;
  // }
}

h3 {
  // font-size: 30px;
  // line-height: 38px;

  font-size: 27px;
  line-height: 1em;
}

ul {
  padding: 0;
  list-style-position: inside;
  list-style-image: none;
  list-style-type: square;

  li:not(:last-child) {
    margin-bottom: 15px;
  }
}

.footer {
  width: 100%;
  height: 70px;
  background-color: rgb(29, 29, 29);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;

  * {
    color: #fff;
  }
}

.footer__links {
  img {
    max-width: 17px;
    vertical-align: middle;
    margin-right: 3px;
  }

  a {
    font-size: 15px;
    margin: 0 15px;
    opacity: 0.95;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 650px) {
  .footer {
    flex-direction: column;
    height: auto;
    padding-bottom: 10px;
  }

  .footer__links a {
    display: block;
    text-align: center;
    margin: 8px 0;
  }
}

@media (max-width: 550px) {
  h1.logo {
    font-size: 17px;
  }

  .header__content {
    font-size: 15px;

    a {
      margin: 0 5px;
    }
  }
}

h1.logo {
  font-size: 20px;
  font-family: 'Roboto Mono', monospace;

  span {
    font-weight: normal;
  }
}






/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*/
</style>
